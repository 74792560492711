import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import ImageInput from "../inputs/ImageInput";
import "./datepicker.css";
import moment from "moment";
import "../../services/utilityService";
import UtilityService from "../../services/utilityService";
import LoadingScreen from "../LoadingScreen";
import FilterableSelect from "../inputs/FilterableSelect";
import { useTranslation, withTranslation } from "react-i18next";

import { Calendar } from "react-date-range";
import { enGB, ar } from "date-fns/locale";

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      lastPage: 4, //can vary depending on the enrollee type,
      isLoadingSection: { profilePicture: false },
      disabled: true,
      dobError: null,
    };
  }

  onNextPage = (e) => {
    const approvedAge = localStorage.getItem(`@enrolle_age`);
    const { t } = this.props;

    e.preventDefault();
    let cPage = this.state.currentPage;
    let nextPage = ++cPage;

    if (this.state.currentPage === 1 && !this.props.dateOfBirth) {
      this.setState({
        dobError: "Required",
      });

      --nextPage;
    } else {
      this.props.onError();
    }

    if (this.state.currentPage === 1 && !this.props.dateOfBirth) {
      this.setState({
        dobError: "Required",
      });

      --nextPage;
    } else {
      this.props.onError();
    }

    if (approvedAge > 65) {
      this.props.onError({
        message:
          "Kindly enter an age below 65 years old for a spouse or an age below 25 years old for children.",
      });
    } else {
      //selected State check
      /////// Temporary fix for the state issue - bypassing check for now
      if (
        this.state.currentPage === 2 &&
        (!this.props.selectedState || Number(this.props.selectedState) === 0)
      ) {
        this.props.onError({ message: t("signUp_governorateErr") });
        --nextPage;
      } else {
        this.props.onError();
      }

      this.setState({
        currentPage: nextPage,
      });
    }
  };

  onPrevPage = () => {
    let cPage = this.state.currentPage;
    let prevPage = --cPage;
    //i.e skip company page, if dependant type is child

    this.setState({
      currentPage: prevPage,
    });
  };

  componentDidMount() {
    const { dispatch, user } = this.props;

    dispatch(change("signUpForm", "first_name", user.first_name));
    dispatch(change("signUpForm", "last_name", user.last_name));
    dispatch(change("signUpForm", "email_address", user.email_address));
    dispatch(change("signUpForm", "home_phone_number", user.phone_number));
    if (user.hash) {
      dispatch(change("signUpForm", "enrollee_type", user.enrolleeType));
      dispatch(change("signUpForm", "hash", user.hash));
    } else if (user.accessToken) {
      dispatch(change("signUpForm", "access_token", user.accessToken));
    }

    dispatch(change("signUpForm", "has_smartphone", "1"));
  }

  onImageUploaded = (img) => {
    const { dispatch, user, onError, t } = this.props;
    //set isLoadingSection in state to true
    //upload img
    //get filename from server
    //change form value
    //set isLoadingSection to false

    this.setState({
      isLoadingSection: {
        ...this.state.isLoadingSection,
        profilePicture: true,
      },
    });

    UtilityService.uploadFile(img, "profile_picture", user.accessToken)
      .then((response) => {
        dispatch(
          change("signUpForm", "profile_picture_filename", response.filename)
        );
        dispatch(change("signUpForm", "profile_picture", img));
        this.setState({
          isLoadingSection: {
            ...this.state.isLoadingSection,
            profilePicture: false,
          },
        });
      })
      .catch((e) => {
        onError({
          message: t("signUp_photoErr"),
          code: -1,
        });
        this.setState({
          isLoadingSection: {
            ...this.state.isLoadingSection,
            profilePicture: false,
          },
        });
        console.log("Error uploading image", e);
      });
  };

  onDateSet = (date) => {
    const { dispatch } = this.props;
    let newDate = new Date(date);

    newDate = moment(newDate).locale("en").format("YYYY-MM-DD");
    dispatch(change("signUpForm", "date_of_birth", newDate));
  };

  onDateError = (error) => {
    this.setState({
      dobError: error,
    });
  };

  onDateError = (error) => {
    this.setState({
      dobError: error,
    });
  };

  render() {
    const {
      error,
      selectedState,
      dateOfBirth,
      profilePicture,
      preferredHospitalLocation,
      data,
      enrolleeType,
      handleSubmit,
      user,
      pristine,
      submitting,
      governorates,
      t,
    } = this.props;
    const { currentPage, lastPage, isLoadingSection, disabled, dobError } =
      this.state;

    function getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    }

    localStorage.setItem(`@enrolle_age`, getAge(dateOfBirth));

    return (
      <form
        onSubmit={currentPage !== lastPage ? this.onNextPage : handleSubmit}
        className="form"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "evenly",
          }}
        >
          {[1, 2, 3, 4].map((x) => {
            return <PageIndicator key={x} isActive={x === currentPage} />;
          })}
        </div>
        {currentPage === 1 && (
          <PersonalInfoPage
            user={user}
            data={data}
            onDateSet={this.onDateSet}
            dob={dateOfBirth}
            dobError={dobError}
            onDateError={this.onDateError}
          />
        )}
        {currentPage === 2 && (
          <ContactInfoPage
            selectedState={selectedState}
            user={user}
            data={data}
            governorates={governorates}
          />
        )}
        {currentPage === 3 && (
          <MedicalInfoPage
            user={user}
            data={data}
            selectedState={selectedState}
            preferredHospitalLocation={preferredHospitalLocation}
          />
        )}
        {currentPage === 4 && (
          <ExtraInfoPage
            user={user}
            data={data}
            profilePicture={profilePicture}
            onImageSet={this.onImageUploaded}
            isLoadingPhoto={isLoadingSection.profilePicture}
            enrolleeType={enrolleeType}
          />
        )}

        {error && (
          <div
            style={{ backgroundColor: "red", color: "#FFF", padding: "10px" }}
          >
            {error}
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Field
            component="input"
            type="hidden"
            name="page"
            value={currentPage}
          />
          {currentPage > 1 && (
            <input
              type="button"
              value={t("signUp_previous")}
              onClick={this.onPrevPage}
              style={{ float: "left" }}
            />
          )}

          <div style={{ width: "100%", textAlign: "center" }}>{`${t(
            `postSignUp_${currentPage}`
          )} / ${t(`postSignUp_${lastPage}`)}`}</div>

          {currentPage >= 1 && currentPage < lastPage && (
            <input
              type="submit"
              value={t("signUp_next")}
              style={{ float: "right" }}
            />
          )}
          {/* {(currentPage >= 1 && currentPage < lastPage) &&
          <>
            {calculatedAge === 0 || calculatedAge > 65 ?
              <input className='next-btn' type="submit" value="Next" style={{float: "right"}} disabled={disabled}/>
            : <input className='next-btn' type="submit" value="Next" style={{float: "right"}} disabled={!disabled}/>}
          </>
          } */}
          {currentPage === lastPage && (
            <input
              type="submit"
              value={t("signUp_submit")}
              disabled={pristine || submitting}
              style={{ float: "right" }}
            />
          )}
        </div>
      </form>
    );
  }
}

const Required = () => (
  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
);

const PersonalInfoPage = (props) => {
  const { t, i18n } = useTranslation(["sign_up"]);

  const { user, dob, dobError, onDateError } = props;
  const [showPicker, setShowPicker] = useState(false);

  const pickerRef = useRef(null);
  const pickerInputRef = useRef(null);

  const companyName = user.company_name;

  const scrollOptions = {
    behavior: "smooth",
    block: "end",
  };

  useEffect(() => {
    onDateError(null);
    if (showPicker) {
      const view = pickerInputRef?.current.scrollIntoView(scrollOptions);
      return view;
    }
  }, [showPicker]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef?.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [pickerRef]);

  return (
    <div style={{ marginTop: "15px" }}>
      <h2 style={{ fontSize: "24px", color: "#094063" }}>
        {t("signUp_header1/4")}
      </h2>
      <p style={{ fontStyle: "italic", fontSize: "10px", color: "#aaa" }}>
        {t("signUp_note")}
      </p>
      <div style={{ marginTop: "10px" }}>
        <span>
          {t("signUp_firstName")} <Required />:
          <Field
            component="input"
            type="text"
            name="first_name"
            placeholder={t("signUp_firstNamePlaceholder")}
            readOnly={!!user.first_name}
            required
            onInvalid={(e) => {
              e.target.setCustomValidity(t("signUp_fNameErr"));
            }}
            onInput={(e) => e.target.setCustomValidity("")}
          />
          <br />
        </span>
        <span>
          {t("signUp_lastName")} <Required />:
          <Field
            component="input"
            type="text"
            name="last_name"
            placeholder={t("signUp_lastNamePlaceholder")}
            readOnly={!!user.last_name}
            required
            onInvalid={(e) => {
              e.target.setCustomValidity(t("signUp_lNameErr"));
            }}
            onInput={(e) => e.target.setCustomValidity("")}
          />
          <br />
        </span>
        <span>
          {t("signUp_gender")} <Required />:<br />
          <Field
            name="sex"
            component="input"
            type="radio"
            value="m"
            className={`${i18n.dir() == "ltr" ? "leftMargin" : "rightMargin"}`}
          />
          {t("signUp_genderMale")}{" "}
          <Field name="sex" component="input" type="radio" value="f" />
          {t("signUp_genderFemale")}
          <br />
        </span>
        <span>
          <div className="dob-label">
            <span>
              {t("signUp_dob")} <Required />:
            </span>
            {dobError && (
              <span className="dobError">{t("signUp_htmlRequiredField")}</span>
            )}
          </div>
          <div ref={pickerRef} className="dobContainer">
            <input
              type="text"
              placeholder={t("signUp_dobPlaceholder")}
              required
              onClick={() => setShowPicker(!showPicker)}
              name="date_of_birth"
              readOnly
              value={dob ? moment(dob).locale(i18n.language).format("L") : ""}
            />

            {showPicker && (
              <div ref={pickerInputRef}>
                <Calendar
                  minDate={companyTypeMaxAge(companyName)}
                  maxDate={new Date()}
                  locale={i18n.language === "ar" ? ar : enGB}
                  onChange={(date) => {
                    props.onDateSet(date);
                    setShowPicker(false);
                  }}
                  date={dob ? new Date(dob) : new Date()}
                  className="form-control"
                />
              </div>
            )}
          </div>
          <br />
        </span>
      </div>
    </div>
  );
};

const ContactInfoPage = (props) => {
  const { t } = useTranslation(["sign_up"]);
  const { data, user, governorates } = props;

  let providers = user.providers
    ? sortProvidersByState(user.providers)
    : sortProvidersByState(data.providers);

  //get unique states only
  providers = providers.reduce((b, i) => {
    if (b.filter((v) => v.state.name === i.state.name).length === 0) b.push(i);
    return b;
  }, []);

  return (
    <div style={{ marginTop: "15px" }}>
      <h2 style={{ fontSize: "24px", color: "#094063" }}>
        {t("signUp_header2/4")}
      </h2>
      <p style={{ fontStyle: "italic", fontSize: "10px", color: "#aaa" }}>
        {t("signUp_note")}
      </p>
      <div style={{ marginTop: "10px" }}>
        <span>
          {t("signUp_phone")} <Required />:
          <Field
            component="input"
            name="home_phone_number"
            type="phone"
            placeholder={
              user.accessToken
                ? t("signUp_phonePlaceholder1")
                : t("signUp_phonePlaceholder2")
            }
            readOnly={!!user.phone_number}
            normalize={normalizePhone}
            required
            onInvalid={(e) => {
              e.target.setCustomValidity(t("signUp_phoneErr"));
            }}
            onInput={(e) => e.target.setCustomValidity("")}
          />
          <br />
        </span>
        <span>
          {t("signUp_email")}:
          <Field
            component="input"
            name="email_address"
            type="email"
            placeholder={
              user.accessToken
                ? t("signUp_emailPlaceholder1")
                : t("signUp_emailPlaceholder2")
            }
            readOnly={!!user.email_address}
          />
          <br />
        </span>
        <span>
          {t("signUp_homeAddress")} <Required />:
          <Field
            component="input"
            name="home_address"
            type="text"
            placeholder={t("signUp_homeAddressPlaceholder")}
            required
            onInvalid={(e) => {
              e.target.setCustomValidity(t("signUp_homeAddressErr"));
            }}
            onInput={(e) => e.target.setCustomValidity("")}
          />
          <br />
        </span>
        <span>
          {t("signUp_governorate")} <Required />
          <Field name="home_address_state_id" component="select" required>
            <option value="0">{t("signUp_governorateErr")}</option>
            {governorates.map((governorate) => (
              <option key={governorate.id} value={governorate.id}>
                {governorate.name}
              </option>
            ))}
          </Field>
        </span>
      </div>
    </div>
  );
};

const MedicalInfoPage = (props) => {
  const { t } = useTranslation(["sign_up"]);
  const { data, user, selectedState } = props;
  let providers = [];
  if (user?.providers) {
    providers = user?.providers;
  } else {
    providers = data?.providers;
  }

  providers = providers.filter(
    (provider) => provider.state.id === Number(selectedState)
  );

  let providerLocations = [];
  providers.map((provider) => providerLocations.push(provider.location));
  providerLocations = [...new Set(providerLocations)];

  return (
    <div style={{ marginTop: "15px" }}>
      <h2 style={{ fontSize: "24px", color: "#094063" }}>
        {t("signUp_header3/4")}
      </h2>
      <p style={{ fontStyle: "italic", fontSize: "10px", color: "#aaa" }}>
        {t("signUp_note")}
      </p>
      <div style={{ marginTop: "10px" }}>
        {providers && providers.length > 0 ? (
          <div className="form-group">
            <Field
              name="preferred_provider_id"
              className="form-input"
              component={FilterableSelect}
              props={{
                items: providers,
                placeholder: t("signUp_hospitalPlaceholder"),
                filterBy: ["name", "address", "state.name"],
                extraKey: "address",
              }}
            />
          </div>
        ) : (
          <div>
            <span>
              {t("signUp_preferredHospital")}:
              <Field
                component="input"
                type="text"
                name="preferred_hospital_name"
                placeholder={t("signUp_preferredHospitalPlaceholder")}
              />
              <br />
            </span>
            <span>
              {t("signUp_hospitalAddress")}:
              <Field
                component="input"
                type="text"
                name="preferred_hospital_address"
                placeholder={t("signUp_hospitalAddressPlaceholder")}
              />
              <br />
            </span>
          </div>
        )}

        <br />
      </div>
    </div>
  );
};

const ExtraInfoPage = (props) => {
  const { t } = useTranslation(["sign_up"]);
  const { data, profilePicture, onImageSet, enrolleeType, isLoadingPhoto } =
    props;

  const translatedMStatus = data.mStatuses.map((status) => {
    if (status.name === "Single") {
      return {
        id: status.id,
        name: t("signUp_single"),
      };
    } else if (status.name === "Married") {
      return {
        id: status.id,
        name: t("signUp_married"),
      };
    } else if (status.name === "Divorced") {
      return {
        id: status.id,
        name: t("signUp_divorced"),
      };
    } else if (status.name === "Widow/Widower") {
      return {
        id: status.id,
        name: t("signUp_widow"),
      };
    }
  });

  return (
    <div style={{ marginTop: "15px" }}>
      <h2 style={{ fontSize: "24px", color: "#094063" }}>
        {t("signUp_header4/4")}
      </h2>
      <p style={{ fontStyle: "italic", fontSize: "10px", color: "#aaa" }}>
        {t("signUp_note")}
      </p>
      <div style={{ marginTop: "10px" }}>
        {enrolleeType !== "3" && (
          <span>
            {t("signUp_maritalStatus")}:
            <Field name="marital_status_id" component="select">
              <option>{t("signUp_maritalStatusPlaceholder")}</option>
              {translatedMStatus.map((status) => (
                <option key={status.id} value={status.id}>
                  {status.name}
                </option>
              ))}
            </Field>
          </span>
        )}

        <Field component="input" type="hidden" name="profile_picture" />
        <Field
          component="input"
          type="hidden"
          name="profile_picture_filename"
        />

        {isLoadingPhoto ? (
          <div style={{ width: "250px", height: "250px" }}>
            <LoadingScreen />
          </div>
        ) : (
          <span>
            {t("signUp_passport")} <Required />
            <ImageInput
              width={250}
              height={250}
              customClassName="sign__form__file-input"
              onCropComplete={onImageSet}
              placeholder={profilePicture}
            />
          </span>
        )}

        <Field component="input" type="hidden" name="enrollee_type" />
        <Field component="input" type="hidden" name="hash" />

        <span>
          {t("signUp_smartPhone")}:
          <br />
          {"  "}
          <Field
            name="has_smartphone"
            component="input"
            type="radio"
            value="1"
          />{" "}
          {t("signUp_yes")} {"  "}
          <Field
            name="has_smartphone"
            component="input"
            type="radio"
            value="0"
          />{" "}
          {t("signUp_no")}
        </span>
      </div>
    </div>
  );
};

const PageIndicator = (props) => {
  return (
    <div
      style={{
        backgroundColor: props.isActive ? "#094063" : "#e6e5e5",
        height: "5px",
        borderRadius: "3px",
        width: "100%",
        margin: "4px",
      }}
    ></div>
  );
};

const normalizePhone = (value) => {
  if (!value) {
    return value;
  }
  return value.replace(/[^\d]/g, "");
};

const sortProvidersByState = (uProviders) => {
  let providers = uProviders;
  for (let i = 0; i < providers.length; i++) {
    for (let j = 0; j < providers.length - 1 - i; j++) {
      if (providers[j].state) {
        if (providers[j].state.name > providers[j + 1].state.name) {
          let temp = providers[j + 1];
          providers[j + 1] = providers[j];
          providers[j] = temp;
        }
      }
    }
  }
  return providers;
};

function getMaxAge(companyName) {
  if (!companyName) return AGE_LIMITS.retail;

  return companyName.includes("RelianceHMO Retail")
    ? AGE_LIMITS.retail
    : AGE_LIMITS.corporate;
}

function companyTypeMaxAge(companyName) {
  return new Date(
    new Date().setFullYear(new Date().getFullYear() - getMaxAge(companyName))
  );
}

const AGE_LIMITS = {
  retail: 120,
  corporate: 65,
};

SignUpForm = reduxForm({
  form: "signUpForm",
  destroyOnUnmount: false,
})(SignUpForm);

// Decorate with connect to read form values
const selector = formValueSelector("signUpForm");
SignUpForm = connect((state) => {
  // can select values individually
  const selectedState = selector(state, "home_address_state_id");
  const dateOfBirth = selector(state, "date_of_birth");
  const profilePicture = selector(state, "profile_picture");
  const enrolleeType = selector(state, "enrollee_type");
  const preferredHospitalLocation = selector(
    state,
    "preferred_hospital_location"
  );

  return {
    selectedState,
    dateOfBirth,
    profilePicture,
    enrolleeType,
    preferredHospitalLocation,
  };
})(SignUpForm);

const signUpFormWithTranslation = withTranslation(["sign_up"])(SignUpForm);
export default signUpFormWithTranslation;
